@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "fafa", 'Tohoma';
}
@font-face {
  font-family: "fafa";
  src: url("../fonts/Yekan.woff") format("woff");
}
body {
  font-size: 1.25rem;
  font-family: "fafa", Helvetica, Arial, sans-serif;
  background: #ffffff;
  background-size: 84px 48px ;
  box-sizing: border-box;
}
.noscroll {
  overflow: hidden;
}
.mh-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  height: 100%;
  position: relative;
  padding-left: 4rem;
}
.mh-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 80%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}
.mh-total-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
  position: relative;
}
.mh-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}
.iframe-class {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
@media only screen and (orientation: portrait) {
  .mh-total-content-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .mh-content-container {
    flex-direction: column;
    width: 100%;
  }
}
.mh-main-card-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 100%;
}
.mh-main-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  min-height: 300px;
  background: #ffffff;
  border: 1px solid;
  border-radius: 10px;
}
.mh-item-selected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  border: 1px solid;
  border-radius: 20px;
}
.mh-item-button-test {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  margin-top: 10px;
  border: 2px solid;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  width: 200px;
}
.mh-item-button-test:hover {
  background: #6dbe7b;
}
.mh-item-button-test-exit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  width: 200px;
  margin-top: 10px;
  border: 2px solid red;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
}
.mh-item-button-test-exit:hover {
  background: #be4f50;
}
.time-line-container {
  width: 100%;
  display: block;
}
.mh-tineline-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: medium;
  padding-right: 15px;
}
.mh-timeline-header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.mh-timeline-header-text-searched {
  font-size: large;
  font-weight: bold;
  padding: 0 10px;
}
.mh-tineline-header-refresh {
  padding: 0 20px;
  cursor: pointer;
}
.mh-tineline-header-refresh:hover {
  font-weight: bold;
}
.mh-add-form {
  margin-top: 70px;
}
.mh-add-bar {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}
.mh-add-bar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  background: #fff86d;
  height: 100%;
  padding: 20px;
  cursor: pointer;
  font-size: small;
}
.mh-add-bar-item:hover {
  background: #e6c88d;
}
.mh-add-bar-item-selected {
  transform: translateY(50%);
}
.mh-main-header-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 0;
}
.mh-main-header {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  min-width: max-content;
  background: #0f6d95;
  color: black;
  flex-grow: 1;
}
.mh-main-header-absolute {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  min-width: max-content;
  color: black;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
@media only screen and (orientation: portrait) {
  .mh-main-header-absolute {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    min-width: max-content;
    background: #0f6d95;
    color: black;
    position: relative;
  }
}
.mh-main-header-absolute {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mh-main-title {
  min-width: 400px;
  width: 100%;
  text-align: center;
  margin: 0 5px;
}
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (orientation: portrait) {
  .menu-container {
    position: relative;
  }
}
.menu-container-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  width: 100%;
  z-index: 90;
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  width: 100%;
  background: #0f6d95;
  color: white;
  transition: top 300ms;
  z-index: 90;
}
.main-divider {
  height: 150px;
  width: 100%;
}
.main-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.main-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 70px;
}
.tl-item-card {
  width: 5rem;
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.tl-item-title {
  width: 100%;
  text-align: center;
  white-space: normal;
  overflow-wrap: break-word;
  font-size: small;
}
.tl-item-img {
  width: 5rem;
  height: 7rem;
  object-fit: cover;
}
@media only screen and (orientation: portrait) {
  .main-menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    position: relative;
    color: white;
    min-height: 70px;
  }
}
.menu-login {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: relative;
  z-index: 5;
}
.menu-otherlink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: 100%;
  min-width: max-content;
  position: relative;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
.main-menu-footer-container {
  font-size: medium;
  background: #0f6d95;
  padding: 2px 10px;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 400px;
}
.main-menu-footer-title {
  font-size: small;
  margin-left: 20px;
}
.main-menu-footer-items {
  padding: 0 5px;
  border-left: 1px solid;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 5px;
}
.main-menu-footer-items:hover {
  background: black;
  color: white;
}
.btn-text {
  padding: 0 5px;
  word-wrap: unset;
}
.menu-otherlink-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  font-size: small;
  height: 30px;
  border: 1px solid;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 6px;
  min-width: fit-content;
}
.menu-otherlink-btn:hover {
  background: black;
  color: white;
  border: 1px solid black;
}
.menu-login-InputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  font-size: medium;
  padding: 0 5px;
  min-width: max-content;
}
.menu-login-InputGroup-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  text-align: left;
}
.menu-login-InputGroup-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid;
  border-radius: 15px;
}
.menu-login-InputGroup-loginbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: small;
  border-radius: 15px;
  min-width: max-content;
  background: black;
  color: white;
  cursor: pointer;
  padding: 0 5px;
  margin: 0 3px;
}
.menu-login-InputGroup-loginbtn:hover {
  background: white;
  color: black;
}
.mh-common-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  left: 10px;
  top: 0;
  background: black;
  color: white;
  cursor: pointer;
  transition: top 300ms;
  z-index: 900;
}
.show-menu {
  top: 0;
}
.show-menu-btn {
  background: #0f6d95;
  top: 30px;
}
.mh-common-menu-btn:hover {
  background: #0f6d95;
  color: white;
}
.menu-welcome-text {
  font-size: medium;
  color: black;
  text-shadow: 0 0 6px white;
  padding: 0 5px;
}
.mh-user-profile-pic {
  width: 80px;
  height: 40px;
  position: relative;
}
.mh-user-profile-pic-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid darkred;
  position: absolute;
  top: 0;
  left: 0;
}
.menu-btn-login-toggle {
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  min-width: 35px;
  height: 35px;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0 5px;
  position: relative;
}
.mh-paginate-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1200px) {
  /* .menu-login{
    display: none;
  }*/
  .menu-btn-login-toggle {
    display: flex;
  }
}
@media screen and (max-width: 800px) {
  /*  .main-menu,.menu-login-InputGroup{
    flex-direction: column;
  }
  .menu-login-InputGroup{
    width: 100%;
  }
  .menu-login{
    flex-direction: column;
    padding: 0;
  }*/
  .main-menu-footer-container {
    font-size: medium;
    background: #0f6d95;
    padding: 2px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
  }
  .mh-main-container {
    width: 100%;
    border: none;
  }
  .menu-login-InputGroup-loginbtn {
    margin: 10px 0;
    width: 50%;
    height: 40px;
  }
  .menu-login-InputGroup-input {
    width: 90%;
    height: 40px;
    margin: 5px 0;
  }
  .menu-otherlink-btn {
    margin: 10px 0;
    width: 50%;
  }
  .show-menu-btn {
    background: #0f6d95;
    top: 0;
  }
  .show-menu {
    top: 0;
  }
  .mh-main-title {
    min-width: 200px;
    width: 100%;
  }
}
.mh-user-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  height: 40px;
  background-color: #ed9189;
  width: 20%;
  min-width: 120px;
  z-index: 2;
  padding: 10px;
  left: 0px;
  border-radius: 0 0 15px 0 ;
  font-size: small;
}
.mh-user-bar-mein-item {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.mh-user-bar-mein-item a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.mh-user-bar-mein-item a:hover {
  color: white;
}
.mh-user-bar-mein-item:hover {
  color: white;
}
.mh-search-grouplabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: medium;
  min-width: 500px;
  width: 100%;
}
.mh-search-text-input {
  border-radius: 5px;
  border: 1px solid;
  height: 30px;
  padding-right: 10px;
  min-width: 190px;
}
.mh-search-group-unit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: medium;
  min-width: max-content !important;
  z-index: 2;
  padding: 0 5px;
  height: 100%;
}
.mh-search-label {
  min-width: 120px;
  text-align: left;
}
.mh-search-searchbtn {
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  padding: 0 8px;
  min-width: 100px;
}
.mh-search-searchbtn:hover {
  background: #0f6d95;
}
.mh-search-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: medium;
  padding: 0 10px;
}
.mh-search-input-group select {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}
@media screen and (max-width: 800px) {
  .mh-search-group-unit {
    min-width: 95%!important;
    flex-grow: 1;
  }
  .mh-search-text-input {
    flex-grow: 1;
  }
}
.mh-timeline-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
}
.mh-timeline-options-bottom-panel {
  display: flex;
  background: #ffffff;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.mh-timeline-options-button-group {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0 40px;
  border-radius: 0 0 15px 0;
}
.mh-timeline-option-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border: 1px solid;
  border-radius: 6px;
  cursor: pointer;
  font-size: medium;
  padding: 3px;
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.mh-timeline-option-button:hover {
  background: #0f6d95;
  color: white;
}
.selected-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.mh-selected {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.mh-selected-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 5px 20px;
  margin-top: 10px;
  border-bottom: 1px dashed;
}
@media only screen and (orientation: portrait) {
  .mh-selected-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.mh-selected-row-group {
  position: relative;
  min-width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
}
.mh-selected-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.mh-selected-pic-container-title {
  font-size: small;
  width: 100%;
  border-bottom: 1px solid;
  margin: 30px 0;
}
.mh-selected-extra-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.mh-selected-extra-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  font-size: medium;
  margin-top: 30px;
}
.mh-selected-extra-text {
  padding: 0 10px;
}
.mh-selected-extra-text-phone {
  color: darkred;
  font-size: large;
  padding: 0 15px;
}
.mh-selected-add-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px;
  margin: 40px;
  font-size: small;
  text-align: center;
  cursor: pointer;
}
.mh-selected-add-cart:hover {
  border: 1px solid;
}
.mh-selected-item-label {
  color: #4c4c4c;
  font-size: small;
}
.mh-selected-title {
  width: 100%;
  background: #ffffff;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: row;
  font-size: medium;
  cursor: pointer;
  height: 36px;
  border-radius: 4px 4px 0 0;
  z-index: 2;
}
.mh-selected-title a {
  text-decoration: none;
  color: white;
}
.mh-selected-item-value {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-size: medium;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
  margin: 0 5px;
}
@media only screen and (orientation: portrait) {
  .mh-selected-item-value {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.mh-selected-item-value:hover {
  color: darkred;
}
.mh-selected-style {
  margin-right: 7px;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
  color: black;
  flex-shrink: 3;
}
.mh-selected-style:hover {
  background: white;
  color: black;
}
.mh-selected-row-group-style {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
@media only screen and (orientation: portrait) {
  .mh-selected-row-group-style {
    align-items: flex-start;
    padding: 0 5px;
  }
}
.mh-selected-container-loaded {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px dashed;
  width: 100%;
  background: white;
}
.mh-selected-pix {
  width: 180px;
  height: 180px;
  margin: 10px 5px;
  font-size: medium;
  cursor: pointer;
  box-sizing: border-box;
  transition: filter 500ms;
  object-fit: cover;
}
.mh-selected-pix:hover {
  filter: grayscale(100%);
  object-fit: contain;
}
.mh-selected-pix-template {
  width: 100px;
  height: 100px;
  margin: 10px;
  font-size: medium;
}
.mh-login-bar {
  width: 100%;
  height: 10%;
  min-height: 80px;
  background: #0f6d95;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mh-input-login {
  height: 30px;
  border-radius: 8px;
  border: 1px solid;
  margin: 3px;
}
.mh-input-group {
  margin-right: 10px;
}
.mh-login-bar-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.mh-input-btn {
  height: 30px;
  border-radius: 8px;
  border: 1px solid;
  width: 80px;
  font-family: "fafa";
  font-size: 15px;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mh-input-btn:hover {
  background: #6a7380;
}
.menu-login-InputGroup button {
  border: none;
  font-family: "fafa", 'Tahoma';
}
@media screen and (max-width: 800px) {
  .mh-login-bar {
    position: relative;
    width: 100%;
    height: 30%;
    min-height: 150px;
    background: #0f6d95;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mh-edit-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px dashed;
  border-radius: 10px;
  margin-top: 70px;
  padding: 10px;
  position: relative;
  padding-top: 40px;
}
.mh-form-title {
  position: absolute;
  right: 20px;
  top: -30px;
  background: #6a7380;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.mh-for-title-icon {
  width: 48px;
  height: 48px;
}
.mh-editt-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  margin-top: 50px;
  padding: 10px;
  background: #6a7380;
  position: relative;
  padding-top: 40px;
}
.mh-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mh-input-group-addform {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}
.mh-input-group-addform textarea {
  resize: none;
}
.mh-addform-label {
  width: 120px;
  text-align: left;
  font-size: small;
}
.mh-input-add-form {
  resize: none;
  height: 30px;
  width: 60%;
  border: 1px solid;
  border-radius: 5px;
}
.mh-input-textarea {
  height: 150px;
  width: 60%;
  border: 1px solid;
  border-radius: 5px;
}
.mh-input-textarea-building {
  height: 150px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
}
.mh-baseClass {
  width: 70%;
}
.mh-input-select {
  height: 30px;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-btn-add {
  width: 200px;
  height: 40px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  transform: translateY(50%);
}
.mh-btn-add:hover {
  background: #4c4c4c;
}
@media screen and (max-width: 800px) {
  .mh-group-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
.CRUD-container {
  width: 80%;
  padding-bottom: 50px ;
}
.mh-top_section {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  align-items: stretch;
  flex-direction: row;
}
.mh-threeDs_section {
  display: flex;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;
  align-items: stretch;
  flex-direction: row;
}
#mycanvas {
  display: flex;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
}
.zarrin {
  margin-top: -100px;
  margin-right: 20px;
}
.mh-top-changebg {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: row;
  z-index: 2;
}
.mh-top-img-previmg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: chocolate;
  border-radius: 5px;
  top: 50%;
  right: 20px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 3;
}
.mh-top-img-nextimg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: chocolate;
  border-radius: 5px;
  top: 50%;
  left: 20px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 3;
}
.mh-top-img-nextimg:hover,
.mh-top-img-previmg:hover {
  border: 2px solid #ffffff;
  box-shadow: 2px 2px;
}
.mh-renderStyle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.bucket-row {
  display: flex;
  width: 100%;
  flex-grow: 1;
}
.colorbucket {
  position: relative;
  display: flex;
  background: #6a7380;
  width: 100px;
  min-height: 100px;
  flex-grow: 1;
}
.color-on {
  box-sizing: border-box;
  animation-name: coloron;
  animation-duration: 1s;
  background-color: rgba(55, 71, 79, 0);
}
@keyframes coloron {
  0% {
    border: 2px solid white;
    background-color: #6a7380;
  }
  100% {
    background-color: rgba(55, 71, 79, 0.16);
  }
}
.mh-Rentorent-butt {
  position: absolute;
  width: 150px;
  height: 60px;
  border-radius: 20px 20px 0 0;
  background: #f2f1ee;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  cursor: pointer;
}
.mh-threeD-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 6;
  color: red;
  text-shadow: 2px 2px #3d3d3d;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}
.mh-Rentorent-butt:hover {
  height: 90px;
  box-shadow: 0 0 30px #f2f1ee;
}
.mh-Con {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.mh-Con-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #0f6d95;
  z-index: 2;
}
.mh-Con-form-input-group {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  margin-top: 5px;
  position: relative;
}
.mh-Con-form-input {
  min-width: 300px;
  width: 80%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #6a7380;
}
.mh-Con-form-textAreainput {
  min-width: 300px;
  width: 80%;
  border-radius: 5px;
  border: 1px solid #6a7380;
  resize: none;
}
.mh-Con-form-details {
  text-align: right;
  width: 80%;
  margin-bottom: 30px;
}
.mh-Con-btn {
  margin: 20px 5px;
  background: black;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  width: max-content;
  height: 40px;
  padding: 0 5px;
  text-align: left;
  cursor: pointer;
  color: white;
  font-size: small;
  max-width: max-content;
}
.mh-Con-btn:hover {
  background: #6a7380;
  color: white;
}
.mh-Con-form-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  position: relative;
}
.image-gallery {
  display: flex;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .mh-Con-form-btn-group {
    flex-direction: column;
  }
  .mh-Con-btn {
    width: 100%;
    max-width: 100%;
  }
}
.mh-top-header {
  position: fixed;
  display: flex;
  width: 100%;
  height: 50px;
  right: 0;
  top: 0;
  background: rgba(0, 12, 23, 0.5);
  z-index: 100;
}
.mh-login-form {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.mh-login-input {
  margin: 0 5px 0 5px;
  height: 30px;
  width: 180px;
  text-align: center;
  border: none;
  border-radius: 5px;
}
.mh-login-button {
  margin: 0 30px 0 20px;
  height: 30px;
  width: 80px;
  text-align: center;
  border: none;
  border-radius: 5px;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-login-button:hover {
  background: #f2f1ee;
}
.mh-login-button-reg {
  margin: 0 10px 0 20px;
  height: 40px;
  width: 80px;
  text-align: center;
  border: none;
  border-radius: 5px;
  font-size: large;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-login-button-reg:hover {
  text-shadow: 0 0 10px #ffffff;
  color: #f2f1ee;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 80%;
  border: 2px dashed;
  border-radius: 5px;
  margin-top: 100px;
}
@media screen and (max-width: 800px) {
  .mh-top-header {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 200px;
    height: 100%;
    right: 0;
    top: 0;
    background: rgba(0, 12, 23, 0.5);
    z-index: 100;
  }
  .mh-login-form {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .mh-login-input {
    margin: 25px 0 0 0;
    height: 50px;
    width: 90%;
    text-align: center;
    border: none;
    border-radius: 5px;
  }
  .mh-login-button {
    margin: 20px;
    height: 30px;
    width: 80px;
    text-align: center;
    border: none;
    border-radius: 5px;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mh-login-button:hover {
    background: #f2f1ee;
  }
}
.auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.mh-Reg-container {
  display: flex;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}
.mh-Reg-form-bg {
  z-index: 2;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  height: 80%;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid;
}
.mh-Reg-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.mh-Reg-form-input-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  position: relative;
}
.mh-Reg-form-label {
  margin-left: 10px;
  font-size: medium;
  text-align: left;
  width: 30%;
}
.mh-Reg-form-input {
  min-width: 300px;
  width: 80%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #6a7380;
}
.mh-Reg-regulation {
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
}
.mh-Reg-regulation:hover {
  color: red;
}
.mh-Reg-btn {
  margin: 20px auto;
  background: black;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  text-align: left;
  cursor: pointer;
  color: white;
}
.mh-Reg-btn:hover {
  background: #6a7380;
  color: black;
}
.mh-Reg-form-Tab-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #6a7380;
}
.mh-Reg-Tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px 10px 0 0 ;
  width: 100%;
  height: 100%;
  background: #6a7380;
  cursor: pointer;
  border-bottom: 1px solid black;
}
.mh-Reg-Tab:hover {
  color: white;
}
.mh-Reg-Tab-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px 20px 0 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
}
.mh-Reg-Tab-active:hover {
  color: white;
}
.mh-rules {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  transform: translate(50%, 10%);
  width: 50%;
  min-width: 600px;
  height: 80%;
  background: #ffffff;
  border-radius: 10px;
  z-index: 100;
}
.mh-rules-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
.mh-rules-closebtn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-content: center;
  width: 70px;
  height: 30px;
  background: #000000;
  cursor: pointer;
  font-size: small;
  border-radius: 10px;
  color: white;
  z-index: 50;
}
.mh-rules-closebtn:hover {
  background: #1d2124;
  z-index: 100;
}
.mh-rules-sections {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  padding: 20px;
  overflow-y: scroll;
  width: 100%;
}
.mh-rules-body {
  text-align: right;
  width: 100%;
}
.mh-rules-sections h4 {
  font-size: medium;
}
.mh-rules-sections h5 {
  font-size: small;
}
.particiles {
  position: fixed;
  width: 100%;
  height: 90%;
  z-index: 0;
}
.mh-Reg-authcode {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: #0f6d95;
  transform: translateX(50%);
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.mh-Reg-form-auth {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-direction: column;
}
@media screen and (max-width: 800px) {
  .mh-Reg-form-bg {
    min-height: 300px;
    margin: 10px 0;
    width: 100%;
    height: 100%;
  }
  .mh-Reg-form-input-group {
    flex-direction: column;
  }
  .mh-Reg-form-input {
    min-width: 80%;
    width: 100%;
    height: 35px;
  }
  .mh-Reg-form-label {
    text-align: right;
    width: 100%;
  }
  .mh-Reg-btn {
    width: 100%;
  }
  .mh-Reg-Tab-active {
    border-radius: 0 ;
  }
  .mh-Reg-Tab {
    border-radius: 0;
  }
}
.mh-selected-full-screen-pic {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-screen-pic-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
.mh-fullscreen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  background: rgba(123, 146, 105, 0.28);
  color: rgba(255, 255, 255, 0.64);
  font-size: medium;
}
.mh-fullscreen-send {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: rgba(255, 255, 255, 0.64);
}
.mh-fullscreen-send-text {
  padding: 0 20px;
}
.pic-frame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pic-frame-container img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.mh-fulscreen-navbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  padding: 0 10px;
  z-index: 125;
  cursor: pointer;
  height: 60px;
}
.mh-fulscreen-navbtn-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.73);
  border-radius: 50%;
  font-size: small;
  color: rgba(0, 0, 0, 0.73);
  background: rgba(255, 255, 255, 0.64);
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.mh-fulscreen-navbtn-btn:hover {
  width: 50px;
  height: 50px;
}
.mh-fullscreen-header-close {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.mh-fullscreen-nave {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.mh-fullscreen-nave:hover {
  background: white;
  color: black;
}
.mh-fullscreen-header-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: x-large;
  color: darkred;
  cursor: pointer;
}
.mh-fullscreen-header-close-btn:hover {
  color: rgba(255, 255, 255, 0.64);
}
.mh-singlePage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.mh-singlePage-brief {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  font-size: medium;
  border: 1px dashed;
}
.mh-singlePage-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  background-color: #0f6d95;
}
.mh-singlePage-header-title {
  font-size: medium;
  cursor: pointer;
}
.mh-singlePage-header-title a {
  text-decoration: none;
  color: black;
}
.mh-singlePage-header-title a:hover {
  color: white;
}
.underConstruct-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.underConstruct-button {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  background: black;
}
.underConstruct-button:hover {
  background: white;
  color: black;
}
.mh-otherpages-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.section-title {
  margin-top: 40px;
  font-size: small;
  width: 80%;
  border-bottom: 1px solid;
  display: flex;
  justify-content: flex-start;
}
.section-card-container img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: white;
}
.section-cards-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  flex-wrap: wrap;
}
.section-card-container {
  min-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}
.section-card-description {
  font-size: small;
  text-align: center;
}
.section-card-description a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.section-card-description a:hover {
  color: darkred;
}
.section-card-description-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mh-comment-reply-container {
  min-height: 20px;
  border-radius: 10px;
  margin: 3px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.mh-comment-reply-container textarea {
  border-radius: 10px;
  width: 100%;
  padding: 0 5px 30px 5px ;
  resize: none;
}
.mh-comment-reply-button-group {
  width: 100%;
  display: flex;
  margin-top: 5px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.mh-comment-reply-button {
  color: white;
  width: 80px;
  height: 35px;
  margin: 0 5px;
  background: black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.hover-cancel:hover {
  background: darkred;
}
.hover-success:hover {
  background: darkgreen;
}
.mh-CommentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: medium;
  border-top: 1px dashed;
  background: white;
  margin-bottom: 5px;
  padding: 20px 10px;
}
.captcha-container {
  margin: 5px 0;
}
.mh-comment-title {
  display: flex;
  padding: 0 10px;
  width: 100%;
  text-align: right;
  font-size: medium;
}
.mh-comment-send {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.mh-comment-title-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  padding: 10px 0;
}
.mh-comment-title-group input {
  border: 1px solid;
  width: 300px;
  height: 30px;
  border-radius: 15px ;
  padding: 10px;
}
.mh-comment-sendInputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.mh-comment-sendInputGroup textarea {
  border: 1px solid;
  width: 100%;
  border-radius: 15px ;
  padding: 10px;
  resize: none;
}
.mh-comment-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: medium;
  border-radius: 10px;
  margin: 2px;
  cursor: pointer;
  padding: 1px 25px;
}
.mh-comment-button-group:hover {
  color: darkred;
  border: 3px solid darkred;
}
.mh-comments-text {
  font-size: medium;
  width: 100%;
  text-align: right;
}
.mh-single-comment-button-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.mh-single-comment-button {
  padding: 0 15px;
  cursor: pointer;
}
.mh-single-comment-button-report {
  padding: 0 15px;
  cursor: pointer;
  font-size: small;
}
.mh-single-comment-button:hover {
  color: darkred;
}
.mh-single-comment-button-report:hover {
  color: darkred;
}
.mh-comment-text-writer {
  font-size: small;
  color: dimgray;
  padding: 0 5px;
}
.mh-comment-single {
  background: rgba(15, 109, 149, 0.15);
  padding: 1px 10px;
  min-height: 20px;
  border-radius: 10px;
  margin: 3px;
  width: 97%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.mh-comment-single-parent {
  min-height: 20px;
  border-radius: 10px;
  margin: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mh-comment-count {
  padding: 0 5px;
  min-width: 50px;
}
.mh-comment-header-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 0 20px;
}
.mh-comment-show-send-comment-button {
  padding: 5px 15px;
  cursor: pointer;
  background: #0f6d95;
  height: 100%;
  border-radius: 0 0 10px 10px;
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.mh-comment-show-send-comment-button:hover {
  background: black;
  color: white;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: small;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.mh-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin-top: 120px;
}
.mh-section-container-dailypics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-radius: 50%;
}
@media only screen and (orientation: portrait) {
  .mh-section-container-dailypics {
    height: max-content;
  }
}
.mh-section-container-border {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
.mh-section-container-dailypic {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
}
.mh-section-title {
  position: absolute;
  right: 15%;
  top: -25px;
  min-width: 30%;
  background: #0f6d95;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: medium;
  box-shadow: 1px 1px black;
  cursor: pointer;
  height: 36px;
  border-radius: 4px 4px 0 0;
  z-index: 2;
}
.mh-section-top-margin {
  margin-top: 20px;
  width: 100%;
  height: 5px;
}
.mh-blog-list-card-container {
  width: 98%;
  height: 150px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: #87898a;
  box-shadow: 1px 1px;
  border-radius: 70px 0 0 0;
}
.mh-blog-card-thumb {
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-blog-card-thumb img {
  min-height: 140px;
  width: 140px;
}
.mh-blog-card-text-side {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
}
.mh-blog-card-title {
  cursor: pointer;
}
.mh-dilypic-card-title {
  width: 100%;
  cursor: pointer;
}
.mh-blog-card-title a {
  text-decoration: none;
  color: black;
}
.mh-blog-card-title:hover {
  text-shadow: white 2px 2px;
}
.mh-blog-card-description {
  width: 100%;
  text-align: right;
  font-size: medium;
}
.mh-blog-card-created {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: small;
  color: grey;
  width: 100%;
}
.mh-blog-card-created-text {
  padding: 0 20px;
}
@media screen and (max-width: 800px) {
  .mh-section-container {
    padding: 20px 0;
  }
  .mh-blog-list-card-container {
    flex-direction: column;
    height: max-content;
  }
  .mh-blog-card-thumb {
    width: 100%;
    margin: 20px 0;
  }
}
.mh-singleBlog-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}
.mh-Blog-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 40px 30px;
  flex-grow: 1;
}
.mh-Blog-title {
  color: #000000;
}
.mh-Blog-time {
  color: gray;
  font-size: small;
}
@media screen and (max-width: 800px) {
  .mh-Blog-container {
    width: 100%;
  }
}
.mh-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  list-style-type: none;
  flex-wrap: wrap;
  font-family: "fafa", 'Tohoma';
  margin-top: 20px;
}
.mh-pagination li {
  font-size: small;
  margin: 0 8px;
}
.mh-pagination li:hover {
  text-shadow: 2px 2px 8px darkred;
}
.mh-pagination li a:hover {
  cursor: pointer;
}
.mh-pagination li a {
  padding: 10px;
}
.mh-pagination-active {
  color: white;
  text-shadow: 2px 2px 8px darkred;
  border: 1px solid;
  background: darkred;
  border-radius: 10px;
}
.mh-pagination-pageClassName {
  cursor: pointer;
}
.mh-pagination-active a {
  text-decoration: none;
}
.mh-pagination-pageClassName:hover {
  color: darkred;
  text-shadow: 2px 2px 8px darkred;
}
.mh-pagination-disabledClassName {
  display: none;
}
.mh-pagination-previousLinkClassName {
  cursor: pointer;
  font-size: medium;
}
.mh-pagination-nextClassName {
  cursor: pointer;
  font-size: medium;
}
.share-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid;
  border-radius: 15px;
  flex-wrap: wrap;
}
.share-main-item-container {
  margin: 0 5px;
  cursor: pointer;
}
.share-main-title {
  font-size: small;
  margin: 0 5px;
}
.message-page-title {
  width: 100%;
  text-align: right;
  padding: 0 10px;
}
.message-page-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}
.msg-card {
  min-width: 500px;
  border: 1px solid;
  border-radius: 20px;
  margin: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
}
.msg-card-title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;
  color: #4c4c4c;
}
.msg-card-time {
  font-size: small;
}
.sub-message-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid;
  border-radius: 20px 0 20px 20px;
  width: 100%;
  margin: 5px 0;
}
.sub-message-user-text {
  width: 100%;
  text-align: right;
  padding: 0 10px;
}
.sub-message-user-time {
  width: 100%;
  text-align: left;
  font-size: small;
  color: #4c4c4c;
  padding: 0 10px;
}
.sub-message-system {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid;
  border-radius: 0 20px 20px 20px;
  width: 100%;
  background: white;
  margin: 5px 0;
}
.sub-message-system-text {
  width: 100%;
  text-align: left;
  padding: 0 10px;
}
.sub-message-system-time {
  width: 100%;
  text-align: right;
  font-size: small;
  padding: 0 10px;
  color: #4c4c4c;
}
.msg-card:hover {
  border-color: darkred;
  background: #0f6d95;
}
.message-page-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
  flex-grow: 1;
  background: #f2f1ee;
  border: 1px solid;
  border-radius: 0 20px 0 0;
  font-size: medium;
  margin: 20px 0;
}
.msg-reply-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
@media screen and (max-width: 800px) {
  .message-page-container {
    width: 100%;
  }
  .msg-card {
    min-width: 100%;
  }
}
.mh-building-addData-card {
  padding: 5px 15px;
  color: black;
  background: #0f6d95;
  border-radius: 0 0 10px 10px;
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  flex-direction: row;
  margin: 0 5px;
  height: 100%;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  flex-direction: row;
  padding: 10px;
  background: @background-color;*/
}
.mh-building-addData-card-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: medium;
  flex-direction: row;
  padding: 2px;
  width: 100%;
  flex-wrap: wrap;
  border: 1px solid;
  border-radius: 10px;
}
.mh-building-addData-card-title {
  font-size: small;
  margin: 0 0 0 40px;
}
.mh-building-addData-card-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 2px 5px;
  border-left: 1px solid;
  border-radius: 10px;
  font-size: small;
  margin: 2px;
  cursor: pointer;
}
.mh-building-addData-card-item:hover {
  background: black;
  color: white;
}
.mh-add-form-back {
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  overflow-y: auto;
}
.frm-container-ByUser {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f2f1ee;
  border: 1px solid;
  border-radius: 0 20px 0 0;
  font-size: medium;
  margin: 20px 0;
  min-width: 800px;
  max-width: 80%;
}
.form-by-user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  border-top: 1px solid;
  width: 100%;
  flex-direction: column;
}
.form-add-by-user-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-add-by-user-title-building-name {
  padding: 0 10px;
  color: darkred;
}
.frm-input-picture-add-by-user {
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 80vh;
  overflow-y: auto;
}
@media screen and (max-width: 800px) {
  .frm-container-ByUser {
    max-width: 100%;
    min-width: 100%;
  }
}
.mh-dailyPic-card-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  color: white;
  pointer-events: none;
}
.mh-dailyPic-card-text-title {
  width: 100%;
  text-align: center;
}
.mh-dailyPic-card-text-title a {
  text-decoration: none;
  color: white;
  font-size: large;
  width: 100%;
}
.mh-dailyPic-card-text-year {
  width: 100%;
  text-align: center;
  font-size: small;
}
.mh-dailyCard-card-thumb {
  height: 400px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 5;
}
/*.mh-dailyCard-card-thumb:hover{
  z-index: 3;
}*/
.mh-dailyCard-card-thumb-active {
  z-index: 3;
}
.mh-dailyCard-card-thumb img {
  height: 400px;
  width: 300px;
  object-fit: cover;
  cursor: pointer;
}
.mh-dailyCard-card-thumbb img {
  height: 250px;
  width: 180px;
  object-fit: cover;
  cursor: pointer;
}
.mh-dailyCard-card-thumbbb img {
  height: 100px;
  width: 75px;
  object-fit: cover;
  cursor: pointer;
}
.mh-dailyCard-card-thumb img:hover {
  object-fit: contain;
  filter: brightness(40%);
  border: 3px solid white;
}
.mh-dailyCard-card-thumbb {
  height: 250px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 5;
}
.mh-dailyCard-card-thumbbb {
  height: 100px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 5;
}
.mh-dailypic-list-card-container {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px 10px 10px 10px ;
  box-sizing: border-box;
  position: relative;
}
.mh-blog-list-card-container:hover {
  box-shadow: 3px 3px #000000;
}
.menu-toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 10px 20px;
}
.share-main-container {
  cursor: pointer;
}
.share-main-container:hover {
  background: black;
}
.mh-toggleMenu-container {
  position: absolute;
  left: 100%;
  top: 100%;
  background: rgba(148, 148, 148, 0.84);
  min-width: 200px;
  border-radius: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 5px;
  z-index: 100;
}
.mh-toggleMenu-item {
  display: flex;
  width: 100%;
  border-radius: 10px;
}
.mh-toggleMenu-item:hover {
  background: black;
}
.mh-toggleMenu-item-text {
  font-size: small;
}
.SocialMediaShareButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  padding: 3px;
  border-radius: 10px;
}
.track-horizontal {
  display: flex;
  width: 100%;
  height: 20px;
}
.mh-main-LP-container {
  width: 100%;
  height: 100vh;
  background: rgba(15, 109, 149, 0.96);
  min-height: 100vh;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
}
.mh-main-LP-container-eneter {
  transform: translateY(-100%);
  transition: transform 1s;
}
.LP-title-side {
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 2px solid #ffffff;
}
.LP-title-side-maintitle-text {
  font-size: xx-large;
  cursor: pointer;
}
.LP-title-side-subtitle-text {
  font-size: medium;
  cursor: pointer;
}
.LP-title-side-eneterbtn {
  margin-top: 50px;
  padding: 5px 10px;
  background: black;
  color: white;
  font-size: medium;
  cursor: pointer;
  border-radius: 5px ;
  text-align: center;
}
.LP-title-side-eneterbtn:hover {
  background: white;
  color: black;
}
.LP-intro-side {
  height: 100%;
  max-width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.LP-intro-side-text {
  width: 100%;
  text-align: right;
  padding: 40px;
  overflow-y: auto;
}
.LP-intro-side-text-title {
  font-size: medium;
  font-weight: bold;
}
.LP-intro-side-text-list {
  padding-right: 50px;
  font-size: small;
}
.LP-intro-side-header-img {
  margin-top: 5%;
  width: 100%;
}
@media only screen and (orientation: portrait) {
  .mh-main-LP-container {
    flex-direction: column;
  }
  .LP-title-side {
    border-left: none;
    width: 100%;
    height: 25%;
  }
  .LP-intro-side {
    width: 100%;
    max-width: 100%;
    height: 100%;
    flex-grow: 1;
  }
  .LP-title-side-eneterbtn {
    margin-top: 5px;
  }
  .LP-intro-side-text {
    padding: 5px;
  }
  .LP-intro-side-text-list {
    font-size: small;
  }
}
.frm-edu-add-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  flex-wrap: wrap;
}
.frm-edu-add-card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: #0f6d95;
  border-radius: 5px;
  border: 1px solid;
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.frm-edu-add-card-text:hover {
  background: white;
}
.frm-edu-card-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #0f6d95;
  border-radius: 5px 40px 5px 5px;
  border: 1px solid;
  margin: 10px;
}
.frm-edu-card-label {
  min-width: 160px;
  text-align: left;
  max-width: max-content;
  font-size: small;
}
.frm-edu-card-input {
  border: 1px solid;
  min-height: 30px;
  border-radius: 5px;
  width: 100%;
  padding: 0 5px;
  font-size: small;
}
.frm-edu-card-input-group {
  padding: 0 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 5px;
}
.frm-edu-close-card {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
}
.frm-edu-close-card:hover {
  color: white;
}
@media screen and (max-width: 800px) {
  .frm-edu-card-input-group {
    flex-direction: column;
  }
  .frm-edu-card-label {
    width: 100%;
    text-align: right;
  }
}
.mh-page-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.mh-page-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background: #0f6d95;
  position: relative;
  padding: 10px;
}
.mh-page-menu-select {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 20px;
  font-size: medium;
  text-align: center;
  font-family: "fafa", 'Tahoma';
}
.mh-panel-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0;
}
.mh-section-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: #a3bb89;
  padding: 0 20px;
}
.mh-section-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.mh-section-filter select {
  height: 30px;
  margin: 0 10px;
  border-radius: 15px;
  font-family: "fafa", 'Tahoma';
  padding: 0 10px;
}
.mh-section-header-btn {
  height: 30px;
  width: 50px;
  border-radius: 15px;
  background: black;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: medium;
}
.mh-section-header-btn:hover {
  border: 2px solid white;
}
.table-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: medium;
  overflow-x: auto;
}
.mh-table-action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.mh-table-action-delete {
  font-size: medium;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-table-action-delete:hover {
  color: red;
}
.mh-table-action-edit {
  font-size: larger;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-table-action-edit:hover {
  color: red;
}
.mh-admin-panel-status-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: medium;
  border-radius: 50%;
  min-width: 120px;
  min-height: 120px;
  border: 2px solid;
  margin: 10px;
}
.mh-admin-panel-status-number {
  color: darkred;
  font-size: large;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
}
th,
td {
  padding: 5px;
}
tr:hover {
  background: #abb9be;
}
.table-header-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-header-filter input {
  height: 30px;
  border-radius: 15px;
  width: 95%;
  border: 1px solid;
  padding: 5px 10px;
}
.table-header-order {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.table-header-order-arrow {
  cursor: pointer;
}
.table-header-order-arrow:hover {
  color: red;
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}
.mh-pic-card-check {
  width: 305px;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-left: 2px solid;
  padding: 2px;
  margin: 5px;
}
.mh-pic-card-check img {
  background-size: cover;
  width: 300px;
  height: 300px;
  object-fit: contain;
}
.mh-pic-card-check-specs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}
.mh-pic-card-check-specs-item {
  width: 100%;
  font-size: small;
}
.mh-pic-card-check-specs-item-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: small;
  color: white;
  text-align: center;
}
.mh-pic-card-check-specs-item-edit {
  width: 100%;
  background: black;
  margin: 2px;
  cursor: pointer;
}
.mh-pic-card-check-specs-item-edit:hover {
  background: gray;
}
.mh-pic-card-check-specs-item-remove {
  width: 100%;
  background: black;
  margin: 2px;
  cursor: pointer;
}
.mh-pic-card-check-specs-item-remove:hover {
  background: darkred;
  color: white;
}
.mh-panel-container-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .mh-page-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #0f6d95;
    position: relative;
    padding: 10px;
  }
  .mh-page-menu-select {
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    border: 1px solid black;
    border-radius: 20px;
    font-size: medium;
    text-align: center;
    font-family: "fafa", 'Tahoma';
  }
  .mh-section-header {
    flex-direction: column;
  }
}
.mh-pic-edit-radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  font-size: medium;
  background: antiquewhite;
}
.mh-pic-edit-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}
.mh-pic-edit-radio-active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
  background: #0f6d95;
}
.mh-pic-edit-radio:hover {
  background: darkred;
  color: white;
}
.mh-pic-edit-radio-label {
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-size: small;
}
.pic-edit-button {
  margin: 10px 0;
  font-size: medium;
  background: #0f6d95;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.pic-edit-button:hover {
  border: 2px solid;
}
.mh-admin-uplPic-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.mh-admin-uplPic-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  max-width: 450px;
  height: 450px;
  border: 2px solid darkred;
  box-sizing: border-box;
  margin: 10px;
}
.mh-admin-uplPic-card-img {
  max-width: 440px;
  max-height: 440px;
  width: auto;
  height: auto;
  min-width: 340px;
}
.mh-admin-uplPic-card-title-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  font-size: small;
  align-items: center;
  top: 0;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  min-width: 200px;
  text-align: right;
  padding: 0 5px;
}
.mh-admin-uplPic-card-title-footer {
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: small;
  align-items: center;
  bottom: 0;
  height: 40px;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  min-width: 200px;
  text-align: right;
  padding: 0 5px;
}
.mh-admin-uplPic-card-buttons {
  display: flex;
  padding: 5px 20px;
  background: black;
  border: 1px solid;
  border-radius: 30px;
  color: white;
  margin: 0 10px;
  cursor: pointer;
}
.mh-admin-uplPic-card-buttons:hover {
  color: black;
  background: white;
}
.mh-admin-newBuilding-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: antiquewhite;
  width: 300px;
  min-height: 150px;
  margin: 10px;
  border: 1px solid darkred;
  border-radius: 30px 30px 30px 0 ;
}
.mh-admin-newBuilding-card-title-header {
  font-size: medium;
  width: 100%;
  text-align: center;
}
.mh-admin-newBuilding-card-title-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: antiquewhite;
  font-size: small;
  margin: 20px 0 ;
}
.mh-admin-newBuilding-card-group-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px dashed;
}
.mh-admin-newBuilding-card-itemLabel {
  font-size: small;
  color: gray;
  min-width: 80px;
  text-align: left;
}
.mh-admin-newBuilding-card-itemText {
  font-size: small;
  padding: 0 10px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.mh-admin-newBuilding-card-pending-item {
  color: darkred;
}
table {
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}
th,
td {
  font-size: medium;
  border-bottom: 1px solid #000000;
  text-align: center;
}
tr:hover {
  background-color: rgba(245, 245, 245, 0.45);
}
.mh-table-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mh-table-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: medium;
}
.mh-table-header-add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: medium;
  border: 1px solid;
  border-radius: 15px;
  padding: 5px;
  transform: translateY(-10px);
  cursor: pointer;
}
.mh-table-header-add:hover {
  background: #0f6d95;
}
.mh-table-list {
  width: 100%;
  position: relative;
}
.mh-table-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mh-table-action-delete {
  width: 50px;
  border: 2px solid darkred;
  border-radius: 5px;
  margin: 4px;
  height: 70%;
  font-size: small;
  cursor: pointer;
}
.mh-table-action-delete:hover {
  background: darkred;
  color: white;
}
.mh-table-action-edit {
  border: 2px solid darkblue;
  border-radius: 5px;
  margin: 4px;
  width: 50px;
  height: 70%;
  font-size: small;
  cursor: pointer;
}
.mh-table-action-edit:hover {
  background: darkblue;
  color: white;
}
.mh-uploadPercent {
  position: absolute;
  display: flex;
  top: 40%;
  width: 100%;
  left: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: large;
}
.img-frame {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  border: 2px solid;
  object-fit: cover;
}
.mh-pix-edit-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 40px;
  position: relative;
}
.mh-pix-edit-container-title {
  position: absolute;
  top: -27px;
  right: 45px;
  background: #6a7380;
  padding: 5px;
}
.mh-img-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
  position: relative;
}
.mh-img-card-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: -10px;
  width: 30px;
  height: 30px;
  transform: translateX(-50%);
  border-radius: 5px;
  background: white;
  border: 1px solid black;
  cursor: pointer;
  z-index: 2;
}
.mh-img-card-input:hover {
  background: #4c4c4c;
  color: white;
}
.mh-img-card-input input {
  display: none;
}
.mh-img-card-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 30px;
  height: 30px;
  padding: 2px;
  background: darkred;
  transform: translateX(-50%);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: 1px solid black ;
}
.mh-img-card-delete:hover {
  background: red;
}
.add-pic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  background: gray;
  position: relative;
  font-size: xx-large;
  border: 1px solid;
  cursor: pointer;
}
.add-pic:hover {
  background: white;
}
.mh-input-select-react {
  min-width: 190px;
  width: max-content;
  max-width: 350px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
}
.cus_select__control {
  width: 100%;
  min-height: 30px !important;
  max-height: 30px !important;
}
.cus_select__control--is-focused {
  width: 100%;
  border-color: black !important;
  min-height: 30px !important;
  max-height: 30px !important;
  box-shadow: none !important;
}
.cus_select__value-container--has-value {
  width: 100%;
}
.cus_select__multi-value {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid;
  font-size: 12px;
}
.cus_select__placeholder {
  top: 30% !important;
}
@media screen and (max-width: 800px) {
  .mh-input-select-react {
    flex-grow: 1;
    max-width: 100%;
  }
}
.ReactTags__tags {
  height: 100%;
  width: 100%;
  padding: 5px;
}
.ReactTags__tagInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.ReactTags__tagInputField {
  resize: none;
  height: 30px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
}
.ReactTags__selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 2px;
}
.ReactTags__tag {
  font-size: medium;
  padding: 3px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 5px;
}
.ReactTags__remove {
  padding: 2px;
  cursor: pointer;
  font-size: large;
}
.ReactTags__remove:hover {
  background: red;
}
.ReactTags__suggestions {
  font-size: large;
}
.ReactTags__activeSuggestion {
  font-size: large;
}
.mh-gform-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 70%;
  padding: 30px 0;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.mh-gform-title {
  font-size: medium;
  width: 40%;
}
.mh-gform-title-select {
  height: 30px;
  border: 1px solid;
  border-radius: 15px;
  width: 100%;
  font-family: "fafa", 'Tohoma';
}
.mh-gform-form {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 95%;
  flex-direction: column;
  border-radius: 10px;
}
.mh-gform-inputgroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  margin-top: 20px;
}
.mh-gform-inputgroup-label {
  font-size: medium;
  min-width: 150px;
  text-align: left;
}
.mh-gform-inputgroup-input {
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: 1px solid;
  font-family: "fafa", 'Tohoma';
  padding-right: 10px;
}
.mh-gform-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px dashed;
  border-radius: 5px;
  margin: 30px 0;
  padding-bottom: 20px;
}
.mh-gform-inputgroup-label-checkbox {
  font-size: small;
  cursor: pointer;
}
.mh-gform-inputgroup-label-checkbox:hover {
  color: white;
}
.mh-gform-InputGroup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: medium;
  border-radius: 15px;
  min-width: 70px;
  background: black;
  color: white;
  cursor: pointer;
  padding: 0 5px;
  margin: 0 10px;
  border: none;
  font-family: "fafa", 'Tohoma';
}
.mh-gform-InputGroup-btn-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: medium;
  border-radius: 15px;
  min-width: 70px;
  cursor: pointer;
  padding: 0 5px;
  margin: 0 10px;
  border: 2px solid red;
  font-family: "fafa", 'Tohoma';
}
.mh-gform-InputGroup-btn-delete:hover {
  background: red;
}
.mh-gform-InputGroup-btn:hover {
  background: white;
  color: black;
}
@media screen and (max-width: 800px) {
  .mh-gform-container {
    width: 100%;
  }
  .mh-gform-inputgroup {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mh-gform-inputgroup-label {
    width: 95%;
    text-align: right;
  }
  .mh-gform-inputgroup-input {
    height: 35px;
  }
  .mh-gform-InputGroup-btn {
    margin: 10px 0;
    width: 50% ;
    height: 40px;
  }
}
.frm-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background: #f2f1ee;
  font-size: medium;
  margin: 20px 0;
}
.frm-container-contact-us {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
  font-size: medium;
  margin: 20px 0;
}
.frm-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  text-decoration: underline;
}
.frm-input-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin: 5px 0;
  padding: 0 5px;
}
.frm-input-group-tr {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0;
  padding: 0 5px;
}
.frm-input-text-label {
  min-width: 140px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: small;
  padding: 0 5px;
}
.frm-input-text-label-contactus {
  min-width: 240px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: small;
  padding: 0 5px;
}
@media only screen and (orientation: portrait) {
  .frm-input-text-label-contactus {
    justify-content: flex-start;
  }
}
.frm-label-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
}
.frm-input-text {
  height: 30px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  padding: 0 5px;
}
.frm-input-textarea {
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  padding: 0 5px;
  resize: vertical;
}
.frm-input-picture-group {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.frm-input-picture-label {
  font-size: small;
  width: 100%;
  text-align: right;
  padding: 0 10px;
}
.frm-button-group {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.frm-button-submit {
  height: 40px;
  min-width: 80px ;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 10px;
  color: white;
  background: black;
  cursor: pointer;
  font-size: small;
}
.frm-button-back {
  height: 40px;
  min-width: 80px ;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 10px;
  color: white;
  background: black;
  cursor: pointer;
  font-size: small;
}
.frm-button-submit:hover {
  background: white;
  color: black;
}
.frm-button-back:hover {
  background: darkred;
}
.frm-comments {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: medium;
  padding: 0 10px;
  margin: 30px 0;
}
@media screen and (orientation: portrait) {
  .frm-container {
    width: 100%;
  }
  .frm-input-group {
    flex-direction: column;
  }
  .frm-button-group {
    flex-direction: column;
  }
  .frm-input-text-label {
    width: 100%;
    justify-content: flex-start;
  }
  .frm-button-submit {
    min-width: 200px ;
  }
  .frm-button-back {
    min-width: 200px ;
  }
}
.pic-upload-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  min-height: 100px;
}
.pic-upload-container-d {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100px;
}
/*.filepond--root{
  overflow-y: auto;
  max-height: 600px;
}*/
.pic-upload-footer-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  font-size: small;
  min-height: 40px;
}
.pic-upload-form-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: small;
}
.pic-cards-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 50px;
  border-top: 1px solid #000000;
}
.mh-img-card-pending {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
  position: relative;
  filter: grayscale(100%);
}
.greyscale {
  filter: grayscale(100%);
}
.mh-pic-item-photographer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 40%;
  height: 30px;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
  font-size: small;
}
.mh-panel-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.mh-panel-group-cards {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.mh-panel-group-cards-status {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.mh-panel-group-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: 100px;
  width: max-content;
  min-height: 40px;
  border: 1px solid;
  border-radius: 30px;
  cursor: pointer;
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  /* iOS Safari */
  /* Safari */
  /* Firefox */
  /* Internet Explorer/Edge */
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  padding: 0 5px;
}
.mh-panel-group-card:hover {
  background: white;
}
.mh-panel-card-text {
  font-size: medium;
  text-align: center;
  width: 100%;
}
.mh-panel-card-text-numbers {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  bottom: -40%;
  left: 0;
  padding: 0 5px;
  border-radius: 30%;
  background-color: #000000;
  color: white;
}
.mh-panel-group-title {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  text-align: right;
  padding: 0 30px;
  font-size: small;
  color: #000000;
  border-bottom: 1px dashed;
}
.leaflet-container {
  height: 100%;
  width: 100%;
}
.mh-map-container {
  display: block;
  width: 100%;
  height: 80vh;
  position: relative;
}
.mh-map-marker-container {
  display: block;
  width: 100%;
  height: 600px;
  position: relative;
  z-index: 0;
}
.mh-map-selected-container {
  display: block;
  width: 100%;
  position: relative;
}
.mh-map-marker {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
  z-index: 900;
}
.mh-location-selected-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-top: 1px dashed;
  font-size: medium;
  background: white;
  padding: 50px 10px;
}
.marker-icon {
  background: none;
  border: none;
}
.Code__code___31Vg8 {
  position: absolute;
  top: 110px;
  right: 10px;
  bottom: 10px;
  width: 425px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 4px;
  padding: 24px;
  font-family: Roboto, monospace;
  white-space: pre;
  font-size: 14px;
  line-height: 22px;
}
.Layout__header___33oX6 {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 0 0 0 20px;
}
.Layout__header___33oX6 h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 64px;
  margin: 0;
  padding: 0;
}
.Layout__header___33oX6 ul {
  list-style: none;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  line-height: 32px;
}
.Layout__header___33oX6 ul li {
  float: left;
  display: block;
  cursor: pointer;
}
.Layout__header___33oX6 ul li:hover {
  border-bottom: 4px solid #00bcd4;
}
.Layout__header___33oX6 ul li.active {
  border-bottom: 4px solid #2196f3;
}
.Layout__header___33oX6 ul li a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
}
.Layout__content___GDRpI {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 20px;
  right: 465px;
}
.Layout__fullWidth___2Qlah {
  left: 0;
  right: 0;
}
.Map__map___2UbOE {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.SearchResults__item___3yUT- > * {
  border: 1px solid transparent;
  line-height: 32px;
  padding: 0 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SearchResults__item___3yUT- > *:hover,
.SearchResults__item___3yUT- > .active {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
}
.Search__search___2kQjw form {
  position: relative;
  margin: 32px 0;
  background-color: #fff;
  vertical-align: top;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.Search__search___2kQjw form:hover,
.Search__search___2kQjw.active form {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.Search__search___2kQjw input {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  outline: none;
  font: 16px arial, sans-serif;
  line-height: 48px;
  height: 48px;
  text-indent: 18px;
}
html,
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.leaflet-control-geosearch.bar {
  position: absolute !important;
  left: 50px;
  right: 515px;
}
/* global styling */
.leaflet__leaflet-control-geosearch___35AKI *,
.leaflet__leaflet-control-geosearch___35AKI *:before,
.leaflet__leaflet-control-geosearch___35AKI *:after {
  box-sizing: border-box;
}
/* leaflet button styling */
.leaflet__leaflet-control-geosearch___35AKI .leaflet__leaflet-bar-part___2_DBQ {
  border-radius: 4px;
  border-bottom: none;
}
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__leaflet-bar-part___2_DBQ:before,
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__leaflet-bar-part___2_DBQ:after {
  position: absolute;
  display: block;
  content: '';
}
/* magnifying glass */
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__leaflet-bar-part___2_DBQ:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__leaflet-bar-part___2_DBQ:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}
/* resets for pending and error icons */
.leaflet__leaflet-control-geosearch___35AKI.leaflet__error___15pc6 a.leaflet__leaflet-bar-part___2_DBQ:before,
.leaflet__leaflet-control-geosearch___35AKI.leaflet__pending___3RDwM a.leaflet__leaflet-bar-part___2_DBQ:before {
  display: none;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__pending___3RDwM a.leaflet__leaflet-bar-part___2_DBQ:after,
.leaflet__leaflet-control-geosearch___35AKI.leaflet__error___15pc6 a.leaflet__leaflet-bar-part___2_DBQ:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}
/* pending icon */
.leaflet__leaflet-control-geosearch___35AKI.leaflet__pending___3RDwM a.leaflet__leaflet-bar-part___2_DBQ:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: leaflet__spin___TqeCo 1s linear infinite;
}
/* error icon */
.leaflet__leaflet-control-geosearch___35AKI.leaflet__error___15pc6 a.leaflet__leaflet-bar-part___2_DBQ:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}
/* search form styling */
.leaflet__leaflet-control-geosearch___35AKI form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__active___WG2p7 form {
  display: block;
}
.leaflet__leaflet-control-geosearch___35AKI form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW {
  background: #fff;
}
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW.leaflet__active___WG2p7 {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW > .leaflet__active___WG2p7,
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}
/* add missing border to form */
.leaflet__leaflet-control-geosearch___35AKI .leaflet__results___19EcW.leaflet__active___WG2p7:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}
/* animations */
@keyframes leaflet__spin___TqeCo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.leaflet__leaflet-top___31xh0 .leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA,
.leaflet__leaflet-bottom___OI2at .leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA {
  display: none;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA form input {
  min-width: 100%;
  width: 100%;
}
.leaflet__leaflet-control-geosearch___35AKI.leaflet__bar___fXvvA .leaflet__results___19EcW.leaflet__active___WG2p7:after {
  opacity: 0.2;
}
.leaflet__leaflet-right___3WKWY .leaflet__leaflet-control-geosearch___35AKI form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__reset___1fyCm {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}
.leaflet__leaflet-control-geosearch___35AKI a.leaflet__reset___1fyCm:hover {
  background: #f5f5f5;
}
/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}
/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}
.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}
/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}
.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}
/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}
.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}
/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}
/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}
/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}
.leaflet-control-geosearch.active form {
  display: block;
}
.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0 20px;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}
.leaflet-control-geosearch .results {
  background: #fff;
}
.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}
.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}
/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}
/* animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.leaflet-top .leaflet-control-geosearch.bar,
.leaflet-bottom .leaflet-control-geosearch.bar {
  display: none;
}
.leaflet-control-geosearch.bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}
.leaflet-control-geosearch.bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.leaflet-control-geosearch.bar form input {
  min-width: 100%;
  width: 100%;
}
.leaflet-control-geosearch.bar .results.active:after {
  opacity: 0.2;
}
.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}
.leaflet-control-geosearch a.reset {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}
.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}
.S-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-top: 60px;
}
.s-tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  font-size: small;
}
.s-tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  border-left: 1px solid;
  cursor: pointer;
  min-height: 50px;
  padding: 0 15px;
  font-size: smaller;
}
.s-tab-item:hover {
  text-decoration: underline;
}
.s-tab-item-active {
  background: white;
  margin-top: 30px;
}
.main-menu-mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;
  color: black;
}
.mh-mobile-header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
  background: #0f6d95;
}
.mh-mobile-header-subline-text {
  background: #0f6d95;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  border-bottom: 2px solid #000000;
}
.menu-mobile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  min-width: 35px;
  height: 35px;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0 5px;
  position: relative;
}
.menu-mobile-btn-addByUser {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 0 5px;
  position: relative;
}
.menu-mobile-btn:hover {
  color: white;
}
.mh-mobile-button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.menu-mobile {
  position: absolute;
  top: 0;
  left: 150%;
  min-width: 300px;
  width: max-content;
  background: rgba(123, 146, 105, 0.96);
  border-radius: 0 20px 0 0;
  border: 1px solid white;
  z-index: 10;
}
.menu-mobile-AddByUser {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 200px;
  width: max-content;
  background: rgba(123, 146, 105, 0.96);
  border-radius: 0 20px 0 0;
  border: 1px solid white;
  z-index: 10;
}
.menu-mobile-AddByUser-Selected {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 200px;
  width: max-content;
  background: rgba(123, 146, 105, 0.96);
  border-radius: 0 20px 0 0;
  border: 1px solid white;
  z-index: 10;
}
.mh-context-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 40px;
  font-size: medium;
}
.mh-context-menu-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid;
  color: black;
  height: 50px;
  padding: 0 10px;
  cursor: pointer;
}
.mh-context-menu-item:hover {
  background: white;
  color: black;
}
.mh-context-menu-lastitem {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: black;
  height: 50px;
  padding: 0 10px;
  cursor: pointer;
}
.mh-context-menu-lastitem:hover {
  background: white;
  color: black;
}
.mh-context-menu-item-text {
  padding: 0 10px;
}
.mh-menu-close-button {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: x-large;
  color: black;
}
.mh-menu-close-button:hover {
  color: darkred;
}
.menu-mobile-void {
  position: relative;
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 100%;
  position: relative;
  background: #ffffff;
  padding-top: 10px;
  margin-bottom: 100px;
  overflow-y: auto;
}
@media only screen and (orientation: portrait) {
  .sidebar-container {
    width: 100%;
  }
}
.sbc-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  position: relative;
  margin-bottom: 60px;
}
.sbc-container-title {
  font-size: medium;
  color: grey;
}
.sb-blog-card-container {
  cursor: pointer;
}
.sb-blog-card-container:hover {
  background: #ffffff;
  border-radius: 30px;
}
.sb-building-card-container {
  padding: 2px 5px;
  cursor: pointer;
}
.sb-building-card-container:hover {
  background: #ffffff;
  border-radius: 10px;
}
.sb-building-card-title a {
  font-size: small;
  text-decoration: none;
  color: black;
}
.sb-building-card-description {
  font-size: x-small;
}
.sb-blog-card-title a {
  font-size: small;
  text-decoration: none;
  color: black;
}
.sb-blog-card-description {
  font-size: x-small;
}
