.mh-Alert-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: rgba(123, 146, 105, 0.5);
  z-index: 100;
}
.mh-alert-message {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  min-height: 50px;
  color: white;
  background: black;
  z-index: 102;
}
.mh-alert-close {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: 5px;
  left: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background: darkred;
}
.mh-alert-close:hover {
  background: black;
  border: 1px solid;
}
.mh-alert-text {
  font-size: medium;
  padding: 10px 10px 10px 40px;
  text-align: center;
}
.alert-text-item {
  font-size: small;
}
@media screen and (max-width: 800px) {
  .mh-alert-message {
    width: 100%;
  }
}
